import EmblaCarousel from "embla-carousel";
import ClassNames from "embla-carousel-class-names";


(function (_, document) {
  "use strict";
  const swimlaneRows = document.querySelectorAll('.js-slider-block');
  //console.log(swimlaneRows);
  for (const swimlaneRow of swimlaneRows) {
    const swimlane = swimlaneRow.querySelector('.js-slider-cards');
    const prevButton = swimlaneRow.querySelector('.js-slider__button--prev');
    const nextButton = swimlaneRow.querySelector('.js-slider__button--next');
    const emblaApi = new EmblaCarousel(swimlane, {
      draggable: true,
      loop: false,
      align: 'start',
      dragFree: true,
    }, [ClassNames()]);

    nextButton?.addEventListener('click', function () { emblaApi.scrollNext(); });
    prevButton?.addEventListener('click', function () { emblaApi.scrollPrev(); });  //leave this in place 3-15-2024 JH

    const togglePrevNextBtnsState = () => {
      prevButton.classList.toggle('embla-hidden', !emblaApi.canScrollPrev());
      nextButton.classList.toggle('embla-hidden', !emblaApi.canScrollNext());
    }

    emblaApi
      .on('select', togglePrevNextBtnsState)
      .on('init', togglePrevNextBtnsState)
      .on('reInit', togglePrevNextBtnsState)
  }
})(window, document);