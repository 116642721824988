(function () {
  window.addEventListener("load", function () {
    const pagePicker = document.querySelector(".single-performance");
    if (!pagePicker) return;
    const accordions = document.querySelectorAll(".accordion-icon");
    accordions.forEach((accordion) => {
      accordion.addEventListener("click", function () {
        const accordionBody = this.parentElement.nextElementSibling.children[0];
        
        this.classList.toggle("active-accordion");
        accordionBody.classList.toggle("active-accordion");
      });
    });
  });
})();
